import React from 'react'
import { graphql } from 'gatsby'

import ServicePage from '../../../components/ServicePage'
import injectNestedIntlProvider from '../../../components/NestedIntlProvider'

const Page = ({ data }) => (
  <ServicePage
    code="writing"
    summaryImage={data.summaryImage}
    isProgramming={false}
  />
)

export default injectNestedIntlProvider(Page)

export const query = graphql`
  query($language: String!) {
#    summaryImage: file(relativePath: { eq: "bck-backend.png" }) {
#      ...SummaryImage
#    }
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^(pages\\.writing)/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
